<template>
	<div class="list">
		<div :class="{ 'listitem': true }" v-for="item in list" @click="toDetail(item.id)"
			:key="item.id">
			<div class="coverbox">

				<img class="cover" :src="item.cover_url" alt="">
			</div>
			<div class="content">
				<div class="topcon">
					<div class="title">{{ item.title }}</div>
					<div class="summary">{{ item.summary }}</div>
				</div>
				<div class="create_at">发布时间：{{ item.created_at }}</div>
			</div>

		</div>

		<div class="item" v-for="item in noticelist" :key="item.id" @click="toDetail(item.id)">
			<div class="titlearea">
				<img v-if="item.is_new" style="display:inline-block;width:48px;margin-right:10px;position: relative;top:5px;"
					src="@/assets/images/new.png" alt="">
				{{ item.title }}
			</div>
			<div class="timearea">{{ item.created_at }}</div>
		</div>

	</div>
</template>

<script>
import { list } from '@/api/website/search'
export default {
	name: 'PcWebsiteIntro',

	data() {
		return {
			list: [],
			noticelist: [],
			page: 1,
			size: 8,
			total: 0,
			search: '',
		}
	},

	computed: {
		site: {
			get() {
				return this.$store.state.site
			},
			set(v) {
				this.$store.dispatch('site', v);
			}
		}
	},

	watch: {
		$route: {
			handler(v) {
				const str = v.query.c
				this.search = str

				this.getlist()
			}
		}
	},



	mounted() {
		const str = this.$route.query.c
		this.search = str

		this.getlist()
	},

	methods: {

		toDetail(id) {
			const tourl = '/' + this.site.path + '/detail/toutiao/' + id
			// this.$router.push(tourl)
			let { href } = this.$router.resolve({ path:tourl })
			window.open(href, '_blank')
		},


		handleCurrentChange(val) {

			// console.log(`当前页: ${val}`);
			this.page = val
			this.getlist()
		},

		getlist() {
			list({ site_id: this.site.id, search: this.search }).then(res => {
				this.list = res.data.headlines
				this.noticelist = res.data.notice
			})
		}
	},
};
</script>

<style lang="scss" scoped>
.item {
	cursor: pointer;
	display: flex;
	height: 48px;
	align-items: center;
	line-height: 48px;
	justify-content: space-between;
	margin: 0 20px;
	border-bottom: solid 1px #F0F3F8;

	.titlearea {
		display: inline-block;
		width: 280px;
		height: 48px;
		font-size: 14px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #000000;
		line-height: 48px;
		text-align: left;
		overflow: hidden; //超出的文本隐藏
		text-overflow: ellipsis; //溢出用省略号显示
		white-space: nowrap; // 默认不换行；

	}

	.timearea {
		width: 82px;
		height: 17px;
		font-size: 12px;
		font-family: PingFangSC-Regular, PingFang SC;
		font-weight: 400;
		color: #A0A0A0;
		line-height: 17px;
	}
}

.page {
	margin-top: 30px;
	margin-bottom: 30px;
}

.list {
	height: auto;
	width: 790px;
	padding: 0 20px;
	background: #fff;

	.listitem {
		cursor: pointer;
		width: 790px;
		height: 160px;
		display: flex;
		align-items: center;
		border-bottom: solid 1px #F2F2F2;

		.coverbox {
			width: 180px;
			height: 120px;

			.cover {
				width: 180px;
				height: 120px;
				object-fit: cover;
			}
		}


		.content {
			width: 600px;
			height: 120px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			margin-left: 10px;

			.topcon {
				width: 600px;

				.title {
					text-align: left;
					height: 28px;
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #000000;
					line-height: 28px;
					overflow: hidden; //超出隐藏
					white-space: nowrap; //不折行
					text-overflow: ellipsis; //溢出显示省略号

				}


				.summary {
					text-align: left;
					height: 40px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #848484;
					line-height: 20px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden; //溢出内容隐藏
					text-overflow: ellipsis; //文本溢出部分用省略号表示
					display: -webkit-box; //特别显示模式
					-webkit-line-clamp: 2; //行数
					line-clamp: 2;
					-webkit-box-orient: vertical; //盒子中内容竖直排列
				}

			}
		}


	}

	.page1:first-child {
		width: 790px;
		height: 232px;
		display: flex;
		align-items: center;
		border-bottom: solid 1px #F2F2F2;

		.coverbox {
			width: 320px;
			height: 192px;

			.cover {
				width: 320px;
				height: 192px;
				object-fit: cover;
			}
		}


		.content {
			width: 454px;
			height: 192px;
			display: flex;
			flex-direction: column;
			justify-content: space-between;
			align-items: flex-start;
			margin-left: 10px;

			.topcon {
				width: 454px;

				.title {
					text-align: left;
					height: 28px;
					font-size: 20px;
					font-family: PingFangSC-Medium, PingFang SC;
					font-weight: 500;
					color: #000000;
					line-height: 28px;
					overflow: hidden; //超出隐藏
					white-space: nowrap; //不折行
					text-overflow: ellipsis; //溢出显示省略号

				}


				.summary {
					text-align: left;
					height: 40px;
					font-size: 14px;
					font-family: PingFangSC-Regular, PingFang SC;
					font-weight: 400;
					color: #848484;
					line-height: 20px;
					text-overflow: -o-ellipsis-lastline;
					overflow: hidden; //溢出内容隐藏
					text-overflow: ellipsis; //文本溢出部分用省略号表示
					display: -webkit-box; //特别显示模式
					-webkit-line-clamp: 6; //行数
					line-clamp: 6;
					-webkit-box-orient: vertical; //盒子中内容竖直排列
				}

			}
		}


	}


}
</style>



